var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "padding-top": "0px" } }, [
    _c(
      "div",
      { staticClass: "row justify-center q-pt-sm" },
      [
        _c("QQButton", {
          staticClass: "q-mb-sm",
          attrs: {
            label: "Nuovo preventivo",
            color: "blue-grey",
            icon: "addchart",
            size: "sm",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.onNuovoPreventivo.apply(null, arguments)
            },
          },
        }),
        _c("QQButton", {
          staticClass: "q-mb-sm",
          attrs: {
            label: "Aggiorna elenco",
            color: "blue-grey",
            icon: "mdi-database-refresh-outline",
            size: "sm",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.aggiornaListaPreventivi.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _vm._m(0),
    _c("hr"),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_loading,
            expression: "!is_loading",
          },
        ],
        staticClass:
          "text-center justify-center q-pa-md q-gutter-sm fixed-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "q-px-md" },
      [
        _c("q-table", {
          staticClass: "my-sticky-header-table no-box-shadow q-mt-none",
          attrs: {
            title: "",
            "wrap-cells": "",
            data: _vm.rows,
            columns: _vm.colonne,
            filter: _vm.filter,
            separator: "cell",
            "row-key": "id",
            "rows-per-page-options": [20, 40, 60],
          },
          on: { "row-click": _vm.selezionaRecordPreventivi },
          scopedSlots: _vm._u([
            {
              key: "top-right",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "panel-cerca" },
                    [
                      _c("q-input", {
                        staticStyle: { "min-width": "350px" },
                        attrs: {
                          dense: "",
                          debounce: "300",
                          outlined: "",
                          placeholder: "Cerca",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c("q-icon", { attrs: { name: "search" } }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "header",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    { attrs: { props: props } },
                    _vm._l(props.cols, function (col) {
                      return _c(
                        "q-th",
                        {
                          key: col.name,
                          staticStyle: {
                            "font-size": "14px",
                            "border-bottom": "1px SOLID #404040",
                            "background-color": "#ffcc99",
                            color: "#000",
                          },
                          attrs: { props: props },
                        },
                        [_c("strong", [_vm._v(_vm._s(col.label))])]
                      )
                    }),
                    1
                  ),
                ]
              },
            },
            {
              key: "body",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    {
                      staticStyle: { cursor: "pointer" },
                      attrs: { props: props },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.selezionaRecordPreventivi(null, props.row)
                        },
                      },
                    },
                    [
                      _c("q-td", {
                        attrs: { "auto-width": "" },
                        domProps: { innerHTML: _vm._s(props.cols[0].value) },
                      }),
                      _c("q-td", { attrs: { "auto-width": "" } }, [
                        _vm._v(" " + _vm._s(props.cols[1].value) + " "),
                      ]),
                      _c("q-td", { attrs: { "auto-width": "" } }, [
                        _vm._v(" " + _vm._s(props.cols[2].value) + " "),
                      ]),
                      _c("q-td", { attrs: { "auto-width": "" } }, [
                        _vm._v(" " + _vm._s(props.cols[3].value) + " "),
                      ]),
                      _c("q-td", { attrs: { "auto-width": "" } }, [
                        _vm._v(
                          " " +
                            _vm._s(props.cols[4].value.replaceAll("_", " ")) +
                            " "
                        ),
                      ]),
                      _c(
                        "q-td",
                        { attrs: { "auto-width": "" } },
                        _vm._l(props.cols[5].value.split(","), function (col) {
                          return _c("div", {
                            key: col.name,
                            attrs: { prop: props },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.VisualizzaDatiProdotto(col)
                              ),
                            },
                          })
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
        _vm._v("Clicca su un preventivo per attivare le opzioni di gestione"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }